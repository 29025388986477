import {classNames, htmlToReact, withPrefix} from '../utils';

import React from 'react';
import _ from 'lodash';

export default class SectionSocialProof extends React.Component {
  render() {
    let section = _.get(this.props, 'section', null);
    return (
      <>
      "hello"
        <section id={_.get(section, 'section_id', null)} className="block block-social-proof outer">
          <div className="inner">
        "hello"
          </div>
        </section>
      </>
    )   
  }
}

